import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Obfuscate from 'react-obfuscate';
import Contact from '../../../components/contact/contact.component';
import Logo from '../../../components/header/logo/logo.component';
import Navbar from '../../../components/header/navbar/navbar.component';
import Config from '../../../config';
import globalStyles from '../../../styles/global.module.scss';
import { StaticPageProps } from '../../../util/constants';
import postStyles from '../../post/post.page.module.scss';

const LegalPage: FunctionComponent<StaticPageProps> = ({ pageContext }) => {
  // @ts-ignore
  const backgroundStyle = { backgroundImage: `url(${Config.images[pageContext.pageName]}')` };

  return (
    <>
      <Helmet>
       <title>{Config.siteTitle} - Legal disclosure</title>
      </Helmet>
      <Navbar/>
      <Logo startSmall={true}/>
      <div className={postStyles.backgroundWrapper}>
        <div className={postStyles.background} style={backgroundStyle}/>
      </div>
      <div className={`${globalStyles.contentContainer} ${postStyles.title}`}>
        <div className={globalStyles.content}>
          <div className={globalStyles.container10}>
            <h1 className={postStyles.title}>Legal disclosure</h1>
          </div>
        </div>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.main}`}>
        <div className={`${globalStyles.content} ${postStyles.postContainer}`}>
          <div className={postStyles.staticContent}>
            Information in accordance with Section 5 TMG
            <h2>Contact Information</h2>
            E-Mail:
            {/* @ts-ignore */}
            <Obfuscate
              email="contact@celestial-static-games.de"
              headers={{
                subject: 'Hey there!'
              }}>
              contact@celestial-static-games.de
            </Obfuscate>
            <br/>Internet address: <a href="https://celestial-static-games.de"
                                      target="_blank">https://celestial-static-games.de</a><br/><br/>
            <h2>Disclaimer</h2>
            Accountability for content<br/>
            The contents of our pages have been created with the utmost care. However, we cannot guarantee the
            contents'
            accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible
            for
            our own content on these web pages. In this matter, please note that we are not obliged to monitor
            the transmitted or saved information of third parties, or investigate circumstances pointing to illegal
            activity.
            Our obligations to remove or block the use of information under generally applicable laws remain
            unaffected by this as per
            §§ 8 to 10 of the Telemedia Act (TMG).

            <br/><br/>Accountability for links<br/>
            Responsibility for the content of
            external links (to web pages of third parties) lies solely with the operators of the linked pages. No
            violations were
            evident to us at the time of linking. Should any legal infringement become known to us, we will remove
            the respective
            link immediately.<br/><br/>Copyright<br/> Our web pages and their contents are subject to German copyright
            law. Unless
            expressly permitted by law, every form of utilizing, reproducing or processing
            works subject to copyright protection on our web pages requires the prior consent of the respective
            owner of the rights.
            Individual reproductions of a work are only allowed for private use.
            The materials from these pages are copyrighted and any unauthorized use may violate copyright laws.
          </div>
        </div>
      </div>
      <div className={`${globalStyles.contentContainer} ${globalStyles.diagonalBackground}`}>
        <Contact/>
      </div>
    </>
  );
};

export default LegalPage;
